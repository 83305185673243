<template>
  <div class="grid">
    <vx-card>
      <div class="grid grid-cols-12 gap-2">
        <div class="col-span-12">
          <fieldset class="p-2 pt-0 border border-grey">
            <legend class="px-1 font-bol"> {{ $t('filtros') }} </legend>
            <div class="flex items-center p-2 flex-wrap gap-4">
              <vs-select
                :label="$t('emails_sent.eventType')"
                multiple
                v-model="filter.eventTypes"
                class="hover:text-primary font-semibold w-full">
                <vs-select-item
                  v-for="item in eventTypeList"
                  :key="item"
                  :value="item"
                  :text="`${item}`"/>
              </vs-select>

              <div class="flex gap-1 ml-auto">
                <vs-checkbox class="text-sm smaller-checkbox" v-model="autoFilter">{{ $t('automatico') }}</vs-checkbox>
                <vs-button class="py-auto px-4" @click="reloadGrid">{{ $t('filtrar') }}</vs-button>
                <vs-button class="py-auto px-4" type="border" @click="clearFilters">{{ $t('action.clear') }}</vs-button>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="w-full mt-5">
        <vs-button
          class="float-right mr-2 ml-6"
          @click="reloadGrid">
          {{ $t('action.reload') }}
        </vs-button>
      </div>
      <Grid ref="emailsGrid"
            :service="service"
            route_name="emails_sent"
            order_column="created_at"
            direction="desc">
        <template v-slot:gridThead>
          <vs-th key="id" sort-key="id">ID</vs-th>
          <vs-th key="subject" sort-key="subject">{{ $t('emails_sent.subject') }}</vs-th>
          <vs-th key="email" sort-key="email">{{ $t('emails_sent.email') }}</vs-th>
          <vs-th key="user.name" sort-key="user.name">{{ $t('emails_sent.userName') }}</vs-th>
          <vs-th key="created_at_fmt" sort-key="created_at">{{ $t('emails_sent.dateTime') }}</vs-th>
        </template>
        <template v-slot:gridData="props">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data" :class="formatCssRow(tr)">
            <vs-td width="5%" key="id">
              <div> {{ tr.id }} </div>
            </vs-td>
            <vs-td width="30%" key="subject">
              <div> {{ tr.subject }}</div>
            </vs-td>
            <vs-td width="20%" key="email">
              <div> {{ tr.email }}</div>
            </vs-td>
            <vs-td width="20%" key="user_name">
              <div> {{ tr.user_name }}</div>
            </vs-td>
            <vs-td width="10%" key="created_at_fmt">
              <div> {{ tr.created_at_fmt }}</div>
            </vs-td>
            <vs-td width="5%">
              <feather-icon icon="EyeIcon"
                            svgClasses="w-6 h-6 text-primary"
                            v-tooltip.top="{
                              content: 'Detalhar envio',
                              delay: { show: 1000, hide: 100 },
                            }"
                            @click="openDetailModal(tr)"/>
            </vs-td>
          </vs-tr>
        </template>
      </Grid>
    </vx-card>

    <vs-popup :title="$t('emails_sent.title_modal')"
              :active.sync="showDetailModal">
      <vx-card class="mb-4">
        <div class="vx-row w-full">
          <div class="vx-col">
            <b>{{ $t('emails_sent.subject') }}:</b>
          </div>
            <div class="vx-col">
              {{emailDetailObjec.subject}}
          </div>
        </div>
        <div class="vx-row w-full">
          <div class="vx-col">
            <b>{{ $t('emails_sent.email') }}:</b>
          </div>
            <div class="vx-col">
              {{emailDetailObjec.email}}
          </div>
        </div>
        <div class="vx-row w-full">
          <div class="vx-col">
            <b>{{ $t('emails_sent.userName') }}:</b>
          </div>
            <div class="vx-col">
              {{emailDetailObjec.user_name}}
          </div>
        </div>
        <div class="vx-row w-full">
          <div class="vx-col">
            <b>{{ $t('emails_sent.messageId') }}:</b>
          </div>
            <div class="vx-col">
              {{emailDetailObjec.message_id}}
          </div>
        </div>
      </vx-card>

      <Grid
        :service="service"
        :route_grid_path="emailSentDetailPath"
        order_column="created_at"
        direction="desc"
        v-if="showDetailModal"
        hide_actions="true"
        hidePagination="true"
        perPage="100"
        hide_searchbar="true"
        :column_formats="{
            'event_type': (val) => {
              return $t(`emails_sent.${val}`)
            }
          }"
        :column_icon="{
            'event_type': (val) => {
              let elementary = 'Icon'

              switch (val) {
                case (null):
                  return ''+elementary
                case ('send'):
                  return 'Send'+elementary
                case ('delivery'):
                  return 'Inbox'+elementary
                case ('open'):
                  return 'BookOpen'+elementary
                case ('click'):
                  return 'MousePointer'+elementary
                case ('bounce'):
                  return 'XSquare'+elementary
                case ('subscription'):
                  return 'List'+elementary
                default:
                  return elementary
                }

              return elementary
            }
          }"
        :css_formats="{
            'event_type': (val) => val == 'bounce' ? 'text-danger' : ''
          }"
        />
    </vs-popup>
  </div>
</template>

<script>
import EmailSentService from '@/services/api/EmailSentService'

export default {
  data: () => ({
    showDetailModal: false,
    emailDetailObjec: {
      subject: '',
      message_id: '',
    },
    selected: null,
    service: null,
    autoFilter: false,
    filter: {
      eventTypes: ''
    },
    eventTypeList:[
      'send',
      'delivery',
      'open',
      'click',
      'reject',
      'bounce',
      'complaint',
      'rendering_failure',
      'delivery_delay',
      'subscription'
    ],
  }),
  methods: {
    openDetailModal(item){
      this.showDetailModal = true
      this.emailDetailObjec = item
    },
    formatCssRow(item){
      const notDelivered = (this._.get(item, 'not_delivered') || this._.get(item, 'deliverydelay'))
      return notDelivered ? 'text-danger' : ''
    },
    reloadGrid(){
      this.setDataFilter()
      this.$refs['emailsGrid'].fetchGridData(true)
    },
    clearFilters() {
      this.filter.eventTypes = []
      this.$refs['emailsGrid'].resetFilters()
      this.reloadGrid()
    },
    setDataFilter(){
      this.$refs['emailsGrid'].fillCustomFilters([
        {key: 'event_types', value: this.filter.eventTypes || ''},
      ])
    },
  },
  computed: {
    emailSentDetailPath() {
      return `${this.emailDetailObjec.message_id}/grid`
    },
  },
  beforeMount() {
    this.service = EmailSentService.build(this.$vs)
  }
}
</script>
