var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid" },
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "grid grid-cols-12 gap-2" }, [
            _c("div", { staticClass: "col-span-12" }, [
              _c("fieldset", { staticClass: "p-2 pt-0 border border-grey" }, [
                _c("legend", { staticClass: "px-1 font-bol" }, [
                  _vm._v(" " + _vm._s(_vm.$t("filtros")) + " "),
                ]),
                _c(
                  "div",
                  { staticClass: "flex items-center p-2 flex-wrap gap-4" },
                  [
                    _c(
                      "vs-select",
                      {
                        staticClass: "hover:text-primary font-semibold w-full",
                        attrs: {
                          label: _vm.$t("emails_sent.eventType"),
                          multiple: "",
                        },
                        model: {
                          value: _vm.filter.eventTypes,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "eventTypes", $$v)
                          },
                          expression: "filter.eventTypes",
                        },
                      },
                      _vm._l(_vm.eventTypeList, function (item) {
                        return _c("vs-select-item", {
                          key: item,
                          attrs: { value: item, text: "" + item },
                        })
                      }),
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex gap-1 ml-auto" },
                      [
                        _c(
                          "vs-checkbox",
                          {
                            staticClass: "text-sm smaller-checkbox",
                            model: {
                              value: _vm.autoFilter,
                              callback: function ($$v) {
                                _vm.autoFilter = $$v
                              },
                              expression: "autoFilter",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("automatico")))]
                        ),
                        _c(
                          "vs-button",
                          {
                            staticClass: "py-auto px-4",
                            on: { click: _vm.reloadGrid },
                          },
                          [_vm._v(_vm._s(_vm.$t("filtrar")))]
                        ),
                        _c(
                          "vs-button",
                          {
                            staticClass: "py-auto px-4",
                            attrs: { type: "border" },
                            on: { click: _vm.clearFilters },
                          },
                          [_vm._v(_vm._s(_vm.$t("action.clear")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "w-full mt-5" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "float-right mr-2 ml-6",
                  on: { click: _vm.reloadGrid },
                },
                [_vm._v(" " + _vm._s(_vm.$t("action.reload")) + " ")]
              ),
            ],
            1
          ),
          _c("Grid", {
            ref: "emailsGrid",
            attrs: {
              service: _vm.service,
              route_name: "emails_sent",
              order_column: "created_at",
              direction: "desc",
            },
            scopedSlots: _vm._u([
              {
                key: "gridThead",
                fn: function () {
                  return [
                    _c("vs-th", { key: "id", attrs: { "sort-key": "id" } }, [
                      _vm._v("ID"),
                    ]),
                    _c(
                      "vs-th",
                      { key: "subject", attrs: { "sort-key": "subject" } },
                      [_vm._v(_vm._s(_vm.$t("emails_sent.subject")))]
                    ),
                    _c(
                      "vs-th",
                      { key: "email", attrs: { "sort-key": "email" } },
                      [_vm._v(_vm._s(_vm.$t("emails_sent.email")))]
                    ),
                    _c(
                      "vs-th",
                      { key: "user.name", attrs: { "sort-key": "user.name" } },
                      [_vm._v(_vm._s(_vm.$t("emails_sent.userName")))]
                    ),
                    _c(
                      "vs-th",
                      {
                        key: "created_at_fmt",
                        attrs: { "sort-key": "created_at" },
                      },
                      [_vm._v(_vm._s(_vm.$t("emails_sent.dateTime")))]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "gridData",
                fn: function (props) {
                  return _vm._l(props.data, function (tr, indextr) {
                    return _c(
                      "vs-tr",
                      {
                        key: indextr,
                        class: _vm.formatCssRow(tr),
                        attrs: { data: tr },
                      },
                      [
                        _c("vs-td", { key: "id", attrs: { width: "5%" } }, [
                          _c("div", [_vm._v(" " + _vm._s(tr.id) + " ")]),
                        ]),
                        _c(
                          "vs-td",
                          { key: "subject", attrs: { width: "30%" } },
                          [_c("div", [_vm._v(" " + _vm._s(tr.subject))])]
                        ),
                        _c("vs-td", { key: "email", attrs: { width: "20%" } }, [
                          _c("div", [_vm._v(" " + _vm._s(tr.email))]),
                        ]),
                        _c(
                          "vs-td",
                          { key: "user_name", attrs: { width: "20%" } },
                          [_c("div", [_vm._v(" " + _vm._s(tr.user_name))])]
                        ),
                        _c(
                          "vs-td",
                          { key: "created_at_fmt", attrs: { width: "10%" } },
                          [_c("div", [_vm._v(" " + _vm._s(tr.created_at_fmt))])]
                        ),
                        _c(
                          "vs-td",
                          { attrs: { width: "5%" } },
                          [
                            _c("feather-icon", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top",
                                  value: {
                                    content: "Detalhar envio",
                                    delay: { show: 1000, hide: 100 },
                                  },
                                  expression:
                                    "{\n                            content: 'Detalhar envio',\n                            delay: { show: 1000, hide: 100 },\n                          }",
                                  modifiers: { top: true },
                                },
                              ],
                              attrs: {
                                icon: "EyeIcon",
                                svgClasses: "w-6 h-6 text-primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openDetailModal(tr)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("emails_sent.title_modal"),
            active: _vm.showDetailModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.showDetailModal = $event
            },
          },
        },
        [
          _c("vx-card", { staticClass: "mb-4" }, [
            _c("div", { staticClass: "vx-row w-full" }, [
              _c("div", { staticClass: "vx-col" }, [
                _c("b", [_vm._v(_vm._s(_vm.$t("emails_sent.subject")) + ":")]),
              ]),
              _c("div", { staticClass: "vx-col" }, [
                _vm._v(" " + _vm._s(_vm.emailDetailObjec.subject) + " "),
              ]),
            ]),
            _c("div", { staticClass: "vx-row w-full" }, [
              _c("div", { staticClass: "vx-col" }, [
                _c("b", [_vm._v(_vm._s(_vm.$t("emails_sent.email")) + ":")]),
              ]),
              _c("div", { staticClass: "vx-col" }, [
                _vm._v(" " + _vm._s(_vm.emailDetailObjec.email) + " "),
              ]),
            ]),
            _c("div", { staticClass: "vx-row w-full" }, [
              _c("div", { staticClass: "vx-col" }, [
                _c("b", [_vm._v(_vm._s(_vm.$t("emails_sent.userName")) + ":")]),
              ]),
              _c("div", { staticClass: "vx-col" }, [
                _vm._v(" " + _vm._s(_vm.emailDetailObjec.user_name) + " "),
              ]),
            ]),
            _c("div", { staticClass: "vx-row w-full" }, [
              _c("div", { staticClass: "vx-col" }, [
                _c("b", [
                  _vm._v(_vm._s(_vm.$t("emails_sent.messageId")) + ":"),
                ]),
              ]),
              _c("div", { staticClass: "vx-col" }, [
                _vm._v(" " + _vm._s(_vm.emailDetailObjec.message_id) + " "),
              ]),
            ]),
          ]),
          _vm.showDetailModal
            ? _c("Grid", {
                attrs: {
                  service: _vm.service,
                  route_grid_path: _vm.emailSentDetailPath,
                  order_column: "created_at",
                  direction: "desc",
                  hide_actions: "true",
                  hidePagination: "true",
                  perPage: "100",
                  hide_searchbar: "true",
                  column_formats: {
                    event_type: function (val) {
                      return _vm.$t("emails_sent." + val)
                    },
                  },
                  column_icon: {
                    event_type: function (val) {
                      var elementary = "Icon"

                      switch (val) {
                        case null:
                          return "" + elementary
                        case "send":
                          return "Send" + elementary
                        case "delivery":
                          return "Inbox" + elementary
                        case "open":
                          return "BookOpen" + elementary
                        case "click":
                          return "MousePointer" + elementary
                        case "bounce":
                          return "XSquare" + elementary
                        case "subscription":
                          return "List" + elementary
                        default:
                          return elementary
                      }

                      return elementary
                    },
                  },
                  css_formats: {
                    event_type: function (val) {
                      return val == "bounce" ? "text-danger" : ""
                    },
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }